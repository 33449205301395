<template>
  <main class="row m-0 pt-4">
    <div class="col-xl-10 mx-auto">
      <div class=" my-5">
        <div class="row m-0">
          <div class="col-xl-3 col-md-3 px-0 py-4">
            <button class="btn my-2 text-white  w-100" :class=" {'selected': type == 'fr'}" @click="type = 'fr'">
              Francais
            </button>
            <button class="btn my-2 text-white  w-100" :class=" {'selected': type == 'en'}" @click="type = 'en'">
              Englais
            </button>
          </div>
          <div class="card col-xl-9 col-md-9 py-4">
            <form id="form" class="card-body" method="POST" @submit.prevent="handleSubmit">
            <div class="form-group my-3 mb-0">
              <label class="pb-2">Video :</label>
              <b-form-input type="text" placeholder="Video Url" v-model="item.video" :class="{ 'is-invalid': typesubmit && $v.item.video.$error }"></b-form-input>
              <div v-if="typesubmit && $v.item.video.$error" class="invalid-feedback">
                <span v-if="!$v.item.video.required"> The Video is required</span>
              </div>
            </div>
            <div class="form-group my-3 mb-0">
              <label class="pb-2">Rules :</label>
              <vue-editor v-model="item.rules" :class="{ 'is-invalid': typesubmit && $v.item.rules.$error }"></vue-editor>
              <div v-if="typesubmit && $v.item.rules.$error" class="invalid-feedback">
                <span v-if="!$v.item.rules.required"> The Rules is required</span>
              </div>
            </div>
            <button type="submit" class=" btn selected my-2 float-end w-25">Save</button>

          </form>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { VueEditor } from "vue2-editor";
import {required} from "vuelidate/lib/validators";
export default {
  name: "Main",
  components: {
    VueEditor
  },
  data(){
    return{
      type:'en',
      typesubmit:false,
      item:{},
      item_fr:{},
      item_en:{},
    }
  },
  watch: {
    type: function () {
      if(this.type == 'fr') this.item = this.item_fr
      else this.item = this.item_en
    }
  },
  validations: {
    item: {
      video: {required},
      rules: {required},
    },
  },
  // computed: {
  //   // a computed getter
  //   item: function () {
  //     // `this` points to the vm instance
  //     return this.message.split('').reverse().join('')
  //   }
  // },
  created(){
    this.getInfos()

  },
  methods:{
    handleSubmit() {
      this.typesubmit = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.addItem();
    },
    addItem(){
      this.item.lang = this.type
      this.$http
          .post('infos',this.item)
          .then(() => {
            this.$alertify.success(" success")
          })
          .catch(error => {
            console.log(error)
          })
    },
    getInfos(){
      this.$http
          .get('infos')
          .then(response => {
           console.log(response.data)
            response.data.forEach( (element) => {
             if(element.lang == 'fr'){
               if(element.type == 'video') this.item_fr.video = element.content
               if(element.type == 'rules') this.item_fr.rules = element.content
               console.log(this.item_fr)
             }
              if(element.lang == 'en'){
                if(element.type == 'video') this.item_en.video = element.content
                if(element.type == 'rules') this.item_en.rules = element.content
              }
            })
            this.type = 'en'
           })
          .catch(error => {
            console.log(error)
          })
    }
  }
}
</script>

<style scoped>

.btn{
  border:1px solid rgba(0, 0, 0, 0.125);
  color: #222831!important;
}
.selected {
  background-color: #222831;
  color: white!important;

}
/*.content {*/
/*  background-color: #00142C;*/
/*  min-height: 60vh!important;*/
/*}*/
</style>